import request from '@/common/request'

class ConciliacaoService {
  buscarTudo() {
    return request.get('pagamentos/conciliacao')
  }

  vincularTituloComPagamento(pagamentoId, titulosIds) {
    return request.put(`/pagamentos/${pagamentoId}/titulos`, titulosIds)
  }

  vincularPagamentoAutomatico() {
    return request.patch('/pagamentos/conciliar')
  }

  vincularPorFiltro(filtroPagamento, filtroTitulo) {
    return request.patch('/pagamentos/conciliar/filtros', null, {
      params: {
        ...filtroPagamento,
        ...filtroTitulo
      }
    })
  }
}

export default new ConciliacaoService()
