<template>
  <v-container fluid>
    <v-card>
      <v-container fluid>
        <v-card-title>
          Conciliação

          <v-spacer />

          <v-chip v-if="processo.status === 'EM_EXECUCAO'" class="ma-2">
            Conciliação automática em execução...
          </v-chip>

          <v-progress-circular v-if="atualizando" :size="20" width="2" indeterminate />
          <v-tooltip v-else left>
            <template #activator="{ on }">
              <v-icon v-on="on" @click="atualizarDados()"> mdi-sync </v-icon>
            </template>

            <span> Atualizar </span>
          </v-tooltip>
        </v-card-title>

        <v-card-text>
          <v-card outlined class="mt-2">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="d-flex align-center">
                    <v-icon color="primary" left> mdi-filter-variant </v-icon>
                    <span class="primary--text">Filtros Pagamentos</span>
                  </div>

                  <template #actions>
                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                  </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-form @submit.prevent="aplicarFiltrosPagamentos">
                    <v-row>
                      <v-col sm="6" md="2">
                        <v-select
                          v-model="filtroPagamento.adquirente"
                          :items="itensAdquirente"
                          label="Adquirente *"
                          @change="changeFiltroPagamentoAdquirente(filtroPagamento.adquirente)"
                        />
                      </v-col>

                      <v-col sm="6" md="3">
                        <v-text-field
                          v-model="filtroPagamento.codigoPagamento"
                          label="Código Pagamento"
                          :loading="buscando"
                          prepend-icon="mdi-numeric"
                        />
                      </v-col>

                      <v-col sm="6" md="7">
                        <v-select
                          v-model="filtroPagamento.empresasSelecionadas"
                          label="Empresa"
                          :loading="buscando"
                          :items="itensEmpresa.pagamento"
                          multiple
                          clearable
                          prepend-icon="mdi-domain"
                        >
                          <template #prepend-item>
                            <v-list-item ripple @click="alterarSelecaoEmpresas('PAGAMENTO')">
                              <v-list-item-action>
                                <v-checkbox v-model="todasEmpresasSelecionadasPagamento" />
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title> Selecionar Tudo </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>

                          <template #selection="{ item, index }">
                            <span v-if="index === 3" class="grey--text text-caption">
                              (+{{ filtroPagamento.empresasSelecionadas.length - 3 }} outras)
                            </span>

                            <span
                              v-else-if="
                                (index < 2 &&
                                  index === filtroPagamento.empresasSelecionadas.length - 1) ||
                                index == 2
                              "
                              class="mr-1"
                            >
                              {{ `${item.text}` }}
                            </span>

                            <span v-else-if="index < 2" class="mr-1"> {{ `${item.text},` }} </span>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col sm="6" md="5">
                        <v-text-field
                          v-model="filtroPagamento.nomeCliente"
                          label="Nome Cliente"
                          :loading="buscando"
                          prepend-icon="mdi-account"
                        />
                      </v-col>

                      <v-col sm="6" md="2">
                        <v-text-field
                          v-model="filtroPagamento.valorPagamento"
                          label="Valor Pagamento"
                          :loading="buscando"
                          prepend-icon="mdi-currency-usd"
                          hint="Use '>' ou '<' para valores maiores/menores. (Ex: >149.99)"
                          autocomplete="off"
                        />
                      </v-col>

                      <v-col sm="6" md="2">
                        <v-menu
                          ref="menuDataInicial"
                          v-model="menuDataInicial"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              v-model="dataPagamentoInicialFormatada"
                              label="Data Inicial"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              :loading="buscando"
                              clearable
                              @click:clear="filtroPagamentoDataInicial = ''"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="filtroPagamentoDataInicial"
                            color="primary"
                            no-title
                            @input="menuDataInicial = false"
                          />
                        </v-menu>
                      </v-col>

                      <v-col sm="6" md="2">
                        <v-menu
                          ref="menuDataFinal"
                          v-model="menuDataFinal"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              v-model="dataPagamentoFinalFormatada"
                              label="Data Final"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              :loading="buscando"
                              clearable
                              @click:clear="filtroPagamentoDataFinal = ''"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="filtroPagamentoDataFinal"
                            color="primary"
                            no-title
                            @input="menuDataFinal = false"
                          />
                        </v-menu>
                      </v-col>

                      <v-col sm="6" md="2">
                        <v-menu
                          ref="menuDataInicialVenda"
                          v-model="menuDataInicialVenda"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              v-model="dataInicialVendaFormatada"
                              label="Data Inicial Venda"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              :loading="buscando"
                              clearable
                              @click:clear="filtroDataInicialVenda = ''"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="filtroDataInicialVenda"
                            color="primary"
                            no-title
                            @input="menuDataInicialVenda = false"
                          />
                        </v-menu>
                      </v-col>

                      <v-col sm="6" md="2">
                        <v-menu
                          ref="menuDataFinalVenda"
                          v-model="menuDataFinalVenda"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              v-model="dataFinalVendaFormatada"
                              label="Data Final Venda"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              :loading="buscando"
                              clearable
                              @click:clear="filtroDataFinalVenda = ''"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="filtroDataFinalVenda"
                            color="primary"
                            no-title
                            @input="menuDataFinalVenda = false"
                          />
                        </v-menu>
                      </v-col>

                      <v-col sm="12" md="8">
                        <v-select
                          v-model="filtroPagamento.tipoPagamento"
                          label="Tipo Pagamento"
                          :loading="buscando"
                          :items="itensTipoPagamento"
                          multiple
                          clearable
                          deletable-chips
                        />
                      </v-col>
                    </v-row>

                    <v-row justify="end">
                      <v-col cols="auto">
                        <v-btn
                          class="ml-4"
                          color="primary"
                          outlined
                          type="submit"
                          :loading="buscando"
                        >
                          <v-icon left> mdi-magnify </v-icon>
                          Aplicar
                        </v-btn>
                        <v-btn
                          class="ml-4"
                          color="normal"
                          outlined
                          type="submit"
                          :loading="buscando"
                          @click.stop.prevent="limparSelecaoDeFiltrosPagamento"
                        >
                          <v-icon left> mdi-autorenew </v-icon>
                          Limpar
                        </v-btn>
                        <v-btn
                          class="ml-4"
                          color="primary"
                          outlined
                          type="submit"
                          :disabled="buscandoXls"
                          :loading="buscandoXls"
                          @click="exportarPagamentoSemConciliacaoXls()"
                        >
                          Exportar Arquivo xls
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>

          <v-card outlined class="mt-2">
            <v-container fluid>
              <v-data-table
                v-model="pagamentoSelecionado"
                :headers="headersPagamentos"
                :items="pagamentos"
                single-select
                show-select
                :loading="buscandoPagamentos"
                loading-text="Carregando os pagamentos..."
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15, 30, 50],
                  showFirstLastPage: true
                }"
                :options.sync="optionsPagamentos"
                :server-items-length="qtdeTotalPagamentos"
              >
                <template #top>
                  <v-toolbar-title> Pagamentos </v-toolbar-title>
                </template>

                <template #[`body.append`]="{ headers }">
                  <tr>
                    <td :colspan="headers.length">
                      <div class="d-flex justify-space-around">
                        <strong>
                          Total Pagamentos:
                          {{
                            sumValorPagamento.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            })
                          }}
                        </strong>
                        <strong>
                          Total Retirado da Conta:
                          {{
                            sumValorRetirada.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            })
                          }}
                        </strong>
                        <strong>
                          Total Taxa:
                          {{
                            sumValorTaxa.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            })
                          }}
                        </strong>
                        <strong>
                          Total Pagamentos Descontando Taxa:
                          {{
                            sumValorPagamentoSemTaxa.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            })
                          }}
                        </strong>
                      </div>
                    </td>
                  </tr>
                </template>

                <template #[`item.valorPagamento`]="{ item }">
                  {{
                    item.valorPagamento.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                  }}
                </template>

                <template #[`item.valorTaxa`]="{ item }">
                  {{
                    item.valorTaxa.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                  }}
                </template>

                <template #[`item.dataHoraPagamento`]="{ item }">
                  {{ formatDateTime(item.dataHoraPagamento) }}
                </template>

                <template #[`item.codigoEventoTransacao`]="{ item }">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <strong v-bind="attrs" v-on="on">{{ item.codigoEventoTransacao }}</strong>
                    </template>
                    <span>{{
                      buscaDescricaoPagamento(item.codigoEventoTransacao, item.adquirente)
                    }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-container>
          </v-card>

          <v-card outlined class="mt-2">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="d-flex align-center">
                    <v-icon color="primary" left> mdi-filter-variant </v-icon>
                    <span class="primary--text">Filtros Títulos</span>
                  </div>

                  <template #actions>
                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                  </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-form @submit.prevent="aplicarFiltrosTitulos">
                    <v-row>
                      <v-col sm="6" md="2">
                        <v-select
                          v-model="filtroTitulo.adquirente"
                          :items="itensAdquirente"
                          label="Adquirente *"
                          @change="changeFiltroTituloAdquirente(filtroTitulo.adquirente)"
                        />
                      </v-col>

                      <v-col sm="6" md="3">
                        <v-text-field
                          v-model="filtroTitulo.codigoAutorizacaoAdquirente"
                          label="Código Autorização"
                        />
                      </v-col>

                      <v-col sm="12" md="7">
                        <v-select
                          v-model="filtroTitulo.empresasSelecionadas"
                          label="Empresa"
                          :loading="buscando"
                          :items="itensEmpresa.titulo"
                          multiple
                          clearable
                          prepend-icon="mdi-domain"
                        >
                          <template #prepend-item>
                            <v-list-item ripple @click="alterarSelecaoEmpresas('TITULO')">
                              <v-list-item-action>
                                <v-checkbox v-model="todasEmpresasSelecionadasTitulo" />
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title> Selecionar Tudo </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>

                          <template #selection="{ item, index }">
                            <span v-if="index === 3" class="grey--text text-caption">
                              (+{{ filtroTitulo.empresasSelecionadas.length - 3 }} outras)
                            </span>

                            <span
                              v-else-if="
                                (index < 2 &&
                                  index === filtroTitulo.empresasSelecionadas.length - 1) ||
                                index == 2
                              "
                              class="mr-1"
                            >
                              {{ `${item.text}` }}
                            </span>

                            <span v-else-if="index < 2" class="mr-1"> {{ `${item.text},` }} </span>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col sm="6" md="3">
                        <v-text-field
                          v-model="filtroTitulo.codigoTitulo"
                          prepend-icon="mdi-numeric"
                          label="Código Título"
                        />
                      </v-col>

                      <v-col sm="6" md="3">
                        <v-text-field
                          v-model="filtroTitulo.valorTitulo"
                          prepend-icon="mdi-currency-usd"
                          label="Valor Título"
                          hint="Use '>' ou '<' para valores maiores/menores. (Ex: >149.99)"
                          autocomplete="off"
                        />
                      </v-col>

                      <v-col sm="6" md="3">
                        <v-menu
                          ref="menuDataVencimentoInicial"
                          v-model="menuDataVencimentoInicial"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              v-model="dataVencimentoInicialFormatada"
                              label="Data Inicial Vencimento"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              :loading="buscando"
                              clearable
                              @click:clear="filtroDataVencimentoInicial = ''"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="filtroDataVencimentoInicial"
                            color="primary"
                            no-title
                            @input="menuDataVencimentoInicial = false"
                          />
                        </v-menu>
                      </v-col>

                      <v-col sm="6" md="3">
                        <v-menu
                          ref="menuDataVencimentoFinal"
                          v-model="menuDataVencimentoFinal"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              v-model="dataVencimentoFinalFormatada"
                              label="Data Final Vencimento"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              :loading="buscando"
                              clearable
                              @click:clear="filtroDataVencimentoFinal = ''"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="filtroDataVencimentoFinal"
                            color="primary"
                            no-title
                            @input="menuDataVencimentoFinal = false"
                          />
                        </v-menu>
                      </v-col>

                      <v-col sm="6" md="3">
                        <v-menu
                          ref="menuDataEmissaoInicial"
                          v-model="menuDataEmissaoInicial"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              v-model="dataEmissaoInicialFormatada"
                              label="Data Inicial Emissão"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              :loading="buscando"
                              clearable
                              @click:clear="filtroDataEmissaoInicial = ''"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="filtroDataEmissaoInicial"
                            color="primary"
                            no-title
                            @input="menuDataEmissaoInicial = false"
                          />
                        </v-menu>
                      </v-col>

                      <v-col sm="6" md="3">
                        <v-menu
                          ref="menuDataEmissaoFinal"
                          v-model="menuDataEmissaoFinal"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              v-model="dataEmissaoFinalFormatada"
                              label="Data Final Emissão"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              :loading="buscando"
                              clearable
                              @click:clear="filtroDataEmissaoFinal = ''"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="filtroDataEmissaoFinal"
                            color="primary"
                            no-title
                            @input="menuDataEmissaoFinal = false"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-col cols="auto">
                        <v-btn
                          class="ml-4"
                          color="primary"
                          outlined
                          type="submit"
                          :loading="buscando"
                        >
                          <v-icon left> mdi-magnify </v-icon>
                          Aplicar
                        </v-btn>
                        <v-btn
                          class="ml-4"
                          color="normal"
                          outlined
                          type="submit"
                          :loading="buscando"
                          @click.stop.prevent="limparSelecaoDeFiltrosTitulo"
                        >
                          <v-icon left> mdi-autorenew </v-icon>
                          Limpar
                        </v-btn>
                        <v-btn
                          class="ml-4"
                          color="primary"
                          outlined
                          type="submit"
                          :disabled="buscandoXls"
                          :loading="buscandoXls"
                          @click="exportarTitulosXls()"
                        >
                          Exportar Arquivo xls
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>

          <v-card outlined class="mt-2">
            <v-container fluid>
              <v-data-table
                v-model="titulosSelecionados"
                :headers="headersTitulos"
                :items="titulos"
                :loading="buscandoTitulos"
                :single-select="false"
                show-select
                loading-text="Carregando os títulos..."
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15, 30, 50],
                  showFirstLastPage: true
                }"
                :options.sync="optionsTitulos"
                :server-items-length="qtdeTotalTitulos"
              >
                <template #top>
                  <v-toolbar-title> Títulos </v-toolbar-title>
                </template>

                <template #[`body.append`]="{ headers }">
                  <tr>
                    <td :colspan="headers.length">
                      <div class="d-flex justify-space-around">
                        <strong>
                          Total Títulos:
                          {{
                            sumValorTitulo.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            })
                          }}
                        </strong>
                      </div>
                    </td>
                  </tr>
                </template>

                <template #[`item.dataEmissaoTitulo`]="{ item }">
                  {{ formatDate(item.dataEmissaoTitulo) }}
                </template>

                <template #[`item.dataVencimentoTitulo`]="{ item }">
                  {{ formatDate(item.dataVencimentoTitulo) }}
                </template>

                <template #[`item.valorTitulo`]="{ item }">
                  {{
                    item.valorTitulo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                  }}
                </template>
              </v-data-table>
            </v-container>

            <v-speed-dial v-model="menuAberto" bottom right fixed>
              <template #activator>
                <v-btn v-model="menuAberto" color="primary" dark fab>
                  <v-icon v-if="menuAberto"> mdi-close </v-icon>

                  <v-tooltip v-else left>
                    <template #activator="{ on }">
                      <v-icon v-on="on"> mdi-plus </v-icon>
                    </template>

                    <span>Relacionar pagamento com títulos</span>
                  </v-tooltip>
                </v-btn>
              </template>

              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn small fab color="primary" @click="relacionar">
                    <v-icon small v-on="on"> mdi-arrow-collapse </v-icon>
                  </v-btn>
                </template>
                <span>Conciliar manualmente</span>
              </v-tooltip>

              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn small fab color="primary" @click="conciliarAutomaticamente">
                    <v-icon small v-on="on">mdi-vector-link</v-icon>
                  </v-btn>
                </template>
                <span>Conciliar automaticamente</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn small fab color="primary" @click="conciliarAutomaticamentePorFiltro">
                    <v-icon small v-on="on">mdi-vector-intersection</v-icon>
                  </v-btn>
                </template>
                <span>Conciliar por filtros</span>
              </v-tooltip>
            </v-speed-dial>
          </v-card>
        </v-card-text>
      </v-container>
    </v-card>

    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />
  </v-container>
</template>

<script>
import TituloService from '@/services/titulo/titulo'
import PagamentoService from '@/services/pagamento/pagamento'
import ConciliacaoService from '@/services/conciliacao/conciliacao'
import ProcessoService from '@/services/automatizacao/processo'
import EmpresaService from '@/services/organizacao/empresa'
import Snackbar from '@/models/ui/snackbar'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import { formataCpfCnpj } from '@/shareds/formatacoes'
import { formatDate, formatDateTime } from '@/shareds/formatacoes'
import { buscaDescricaoPagamento, buscaTipoPagamentoFiltro } from '@/shareds/tipoPagamento'
import OrganizacaoService from '@/services/organizacao/organizacao'
import JSZip from 'jszip'

export default {
  components: {
    SnackbarMessage
  },

  data: () => ({
    pagamentoSelecionado: [],
    titulosSelecionados: [],
    pagamentos: [],
    titulos: [],
    sumValorPagamento: 0,
    sumValorRetirada: 0,
    sumValorTaxa: 0,
    sumValorPagamentoSemTaxa: 0,
    sumValorTitulo: 0,
    menuAberto: false,

    optionsPagamentos: {
      page: 1,
      itensPerPage: 0,
      sortBy: []
    },
    qtdeTotalPagamentos: 0,
    optionsTitulos: {
      page: 1,
      itensPerPage: 0,
      sortBy: []
    },
    qtdeTotalTitulos: 0,

    itensAdquirente: [
      { text: 'PayPal', value: 'PAYPAL' },
      { text: 'PagSeguro', value: 'PAGSEGURO' },
      { text: 'GetNet', value: 'GETNET' },
      { text: 'Rede', value: 'REDE' }
    ],

    headersTitulos: [
      { text: 'Adquirente', value: 'adquirente' },
      { text: 'Codigo Autorização', value: 'codigoAutorizacaoAdquirente' },
      { text: 'Empresa', value: 'empresa.nome' },
      { text: 'Data Emissão', value: 'dataEmissaoTitulo' },
      { text: 'Data Vencimento', value: 'dataVencimentoTitulo' },
      { text: 'Codigo Título', value: 'codigoTitulo' },
      { text: 'Parcela Título', value: 'parcelaTitulo' },
      { text: 'Valor Título', value: 'valorTitulo' },
      { text: '', value: 'acoes', width: '1%', sortable: false }
    ],
    headersPagamentos: [
      { text: 'Adquirente', value: 'adquirente' },
      { text: 'Código Pagamento', value: 'transactionId' },
      { text: 'Empresa', value: 'empresa.nome' },
      { text: 'Nome Cliente', value: 'nomeCliente' },
      { text: 'Email Cliente', value: 'emailCliente' },
      { text: 'Valor Pagamento', value: 'valorPagamento', width: '10%' },
      { text: 'Valor Taxa', value: 'valorTaxa', width: '10%' },
      { text: 'Parcela', value: 'parcela', width: '10%' },
      { text: 'Data Pagamento', value: 'dataHoraPagamento' },
      { text: 'Data Venda', value: 'dataVendaFormatada' },
      { text: 'Tipo Pagamento', value: 'codigoEventoTransacao' },
      { text: '', value: 'acoes', width: '1%', sortable: false }
    ],

    itensTipoPagamento: [],
    itensEmpresa: {
      pagamento: [],
      titulo: []
    },
    formataCpfCnpj: formataCpfCnpj,
    formatDateTime: formatDateTime,
    buscaDescricaoPagamento: buscaDescricaoPagamento,
    formatDate: formatDate,
    snackbar: new Snackbar(),
    buscandoPagamentos: false,
    buscandoTitulos: false,
    buscando: false,
    buscandoXls: false,
    atualizando: false,
    filtroPagamento: {
      tipoPagamento: [],
      empresasSelecionadas: []
    },
    filtroTitulo: {
      empresasSelecionadas: []
    },
    processo: {},
    menu: false,
    menuDataInicial: false,
    menuDataFinal: false,
    dataPagamentoInicialFormatada: '',
    dataPagamentoFinalFormatada: '',
    filtroPagamentoDataInicial: '',
    filtroPagamentoDataFinal: '',
    filtroDataInicialVenda: '',
    filtroDataFinalVenda: '',

    menuDataVencimentoInicial: false,
    menuDataVencimentoFinal: false,
    menuDataEmissaoInicial: false,
    menuDataEmissaoFinal: false,
    menuDataInicialVenda: false,
    menuDataFinalVenda: false,

    dataVencimentoInicialFormatada: '',
    dataVencimentoFinalFormatada: '',
    dataEmissaoInicialFormatada: '',
    dataEmissaoFinalFormatada: '',
    dataInicialVendaFormatada: '',
    dataFinalVendaFormatada: '',

    filtroDataVencimentoInicial: '',
    filtroDataVencimentoFinal: '',
    filtroDataEmissaoInicial: '',
    filtroDataEmissaoFinal: '',

    exportarPagamentos: []
  }),

  computed: {
    todasEmpresasSelecionadasPagamento: {
      get() {
        return (
          this.filtroPagamento.empresasSelecionadas.length === this.itensEmpresa.pagamento.length
        )
      },
      set(value) {
        return value
      }
    },
    todasEmpresasSelecionadasTitulo: {
      get() {
        return this.filtroTitulo.empresasSelecionadas.length === this.itensEmpresa.titulo.length
      },
      set(value) {
        return value
      }
    }
  },

  watch: {
    filtroPagamentoDataInicial() {
      this.dataPagamentoInicialFormatada = formatDate(this.filtroPagamentoDataInicial)
    },

    filtroPagamentoDataFinal() {
      this.dataPagamentoFinalFormatada = formatDate(this.filtroPagamentoDataFinal)
    },

    filtroDataVencimentoInicial() {
      this.dataVencimentoInicialFormatada = formatDate(this.filtroDataVencimentoInicial)
    },

    filtroDataVencimentoFinal() {
      this.dataVencimentoFinalFormatada = formatDate(this.filtroDataVencimentoFinal)
    },

    filtroDataEmissaoInicial() {
      this.dataEmissaoInicialFormatada = formatDate(this.filtroDataEmissaoInicial)
    },

    filtroDataEmissaoFinal() {
      this.dataEmissaoFinalFormatada = formatDate(this.filtroDataEmissaoFinal)
    },

    filtroDataInicialVenda() {
      this.dataInicialVendaFormatada = formatDate(this.filtroDataInicialVenda)
    },

    filtroDataFinalVenda() {
      this.dataFinalVendaFormatada = formatDate(this.filtroDataFinalVenda)
    },

    pagamentoSelecionado() {
      if (!this.pagamentoSelecionado[0]) this.filtroTitulo.codigoAutorizacaoAdquirente = null
      else
        this.filtroTitulo.codigoAutorizacaoAdquirente = this.pagamentoSelecionado[0].transactionId
    },

    optionsPagamentos: {
      handler() {
        if (this.filtroPagamento.adquirente) {
          this.buscarPagamentos()
        }
      },

      deep: true
    },

    optionsTitulos: {
      handler() {
        if (this.filtroTitulo.adquirente) {
          this.buscarTitulos()
        }
      },

      deep: true
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      await this.buscaAdquirentePadraoPelaOrganizacao('AMBOS')
      this.changeFiltroPagamentoAdquirente(this.filtroPagamento.adquirente)
      this.buscarPagamentos()

      this.changeFiltroTituloAdquirente(this.filtroTitulo.adquirente)
      this.buscarTitulos()

      this.atualizarDados()
    },

    async atualizarDados() {
      this.atualizando = true

      await new Promise((r) => setTimeout(r, 2000))
      this.processo = await ProcessoService.buscarPeloNomeEOrganizacao('CONCILIACAO_AUTOMATICA')

      this.atualizando = false
    },

    aplicarFiltrosTitulos() {
      Object.assign(this.optionsTitulos, {
        page: 1
      })

      if (this.filtroTitulo.valorTitulo?.includes(' ')) {
        this.snackbar.snackTextParam =
          'Valor título informado possui o formato inválido! (Use > ou < ' +
          ' para valores maiores/menores com o valor de título sem utilizar espaços. (Ex: >149.99))'
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
      } else {
        this.buscarTitulos()
      }
    },

    async buscarTitulos() {
      this.buscandoTitulos = true

      try {
        const paginacao = {
          page: this.optionsTitulos.page - 1,
          size: this.optionsTitulos.itemsPerPage,
          sort: this.optionsTitulos.sortBy.map(
            (sort, indice) => `${sort},${this.optionsTitulos.sortDesc[indice] ? 'DESC' : 'ASC'}`
          )[0]
        }

        const filtros = {
          adquirente: this.filtroTitulo.adquirente || undefined,
          codigoTitulo: this.filtroTitulo.codigoTitulo || undefined,
          valorTitulo: this.filtroTitulo.valorTitulo
            ? this.filtroTitulo.valorTitulo.replaceAll('.', '').replaceAll(',', '.')
            : undefined,
          codigoAutorizacaoAdquirente: this.filtroTitulo.codigoAutorizacaoAdquirente || undefined,
          empresasIds:
            this.filtroTitulo.empresasSelecionadas.length > 0
              ? this.filtroTitulo.empresasSelecionadas.join(',')
              : undefined,
          dataInicialVencimentoTitulo: this.filtroDataVencimentoInicial || undefined,
          dataFinalVencimentoTitulo: this.filtroDataVencimentoFinal || undefined,
          dataInicialEmissaoTitulo: this.filtroDataEmissaoInicial || undefined,
          dataFinalEmissaoTitulo: this.filtroDataEmissaoFinal || undefined,
          conciliados: false
        }

        const response = await TituloService.buscarTudo({ ...filtros, ...paginacao })

        this.titulos = response.content
        this.qtdeTotalTitulos = response.totalElements

        this.buscarValorTotalTitulos(filtros)
      } catch (erro) {
        this.snackbar.snackTextParam = `${erro.response.status} | ${
          erro.response.data.message === undefined
            ? erro.message
            : erro.response.data.message
            ? erro.response.data.message
            : 'Ocorreu um erro ao buscar os títulos'
        }`
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
      } finally {
        this.buscandoTitulos = false
      }
    },

    async buscaAdquirentePadraoPelaOrganizacao(tipoFiltro) {
      await OrganizacaoService.buscaAdquirentePadrao()
        .then((response) => {
          if (tipoFiltro === 'PAGAMENTO') {
            this.filtroPagamento.adquirente = response
          } else if (tipoFiltro === 'TITULO') {
            this.filtroTitulo.adquirente = response
          } else {
            this.filtroTitulo.adquirente = response
            this.filtroPagamento.adquirente = response
          }
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar o adquirente.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    },

    buscarValorTotalTitulos(filtros) {
      TituloService.buscarValorTotal(filtros)
        .then((response) => {
          this.sumValorTitulo = response
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar o valor total dos títulos.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoValores = false))
    },

    async limparSelecaoDeFiltrosTitulo() {
      this.filtroTitulo = {
        empresasSelecionadas: []
      }

      await this.buscaAdquirentePadraoPelaOrganizacao('TITULO')

      this.filtroDataVencimentoInicial = ''
      this.filtroDataVencimentoFinal = ''
      this.filtroDataEmissaoInicial = ''
      this.filtroDataEmissaoFinal = ''
      this.optionsTitulos = {
        page: 1,
        sortBy: [],
        itensPerPage: 0
      }

      this.changeFiltroTituloAdquirente(this.filtroTitulo.adquirente)

      this.buscarTitulos()
    },

    aplicarFiltrosPagamentos() {
      Object.assign(this.optionsPagamentos, {
        page: 1
      })

      if (this.filtroPagamento.valorPagamento?.includes(' ')) {
        this.snackbar.snackTextParam =
          'Valor pagamento informado possui o formato inválido! (Use > ou < ' +
          ' para valores maiores/menores com o valor de pagamento sem utilizar espaços. (Ex: >149.99))'
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
      } else {
        this.buscarPagamentos()
      }
    },

    changeFiltroPagamentoAdquirente(adquirente) {
      this.itensTipoPagamento = buscaTipoPagamentoFiltro(adquirente)
      this.buscarItensEmpresa(adquirente, 'PAGAMENTO')
    },

    changeFiltroTituloAdquirente(adquirente) {
      this.buscarItensEmpresa(adquirente, 'TITULO')
    },

    buscarItensEmpresa(adquirente, filtro) {
      if (filtro === 'PAGAMENTO') {
        this.itensEmpresa.pagamento = []
        this.filtroPagamento.empresasSelecionadas = []
      } else if (filtro === 'TITULO') {
        this.itensEmpresa.titulo = []
        this.filtroTitulo.empresasSelecionadas = []
      }

      EmpresaService.buscarTudo({ adquirente })
        .then((response) => {
          const itens = response.map((item) => ({
            text: `${formataCpfCnpj(item.documento)} - ${item.nome}`,
            value: item.id
          }))

          if (filtro === 'PAGAMENTO') {
            this.itensEmpresa.pagamento = itens
          } else if (filtro === 'TITULO') {
            this.itensEmpresa.titulo = itens
          }
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os empresas.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoEmpresa = false))
    },

    alterarSelecaoEmpresas(filtro) {
      this.$nextTick(() => {
        if (filtro === 'PAGAMENTO') {
          if (this.todasEmpresasSelecionadasPagamento) {
            this.filtroPagamento.empresasSelecionadas = []
          } else {
            this.filtroPagamento.empresasSelecionadas = this.itensEmpresa.pagamento
              .slice()
              .map((item) => item.value)
          }
        } else if (filtro === 'TITULO') {
          if (this.todasEmpresasSelecionadasTitulo) {
            this.filtroTitulo.empresasSelecionadas = []
          } else {
            this.filtroTitulo.empresasSelecionadas = this.itensEmpresa.titulo
              .slice()
              .map((item) => item.value)
          }
        }
      })
    },

    async buscarPagamentos() {
      this.buscandoPagamentos = true

      try {
        const paginacao = {
          page: this.optionsPagamentos.page - 1,
          size: this.optionsPagamentos.itemsPerPage,
          sort: this.optionsPagamentos.sortBy.map(
            (sort, indice) => `${sort},${this.optionsPagamentos.sortDesc[indice] ? 'DESC' : 'ASC'}`
          )[0]
        }

        const filtros = {
          adquirente: this.filtroPagamento.adquirente || undefined,
          codigoPagamento: this.filtroPagamento.codigoPagamento || undefined,
          empresasIds:
            this.filtroPagamento.empresasSelecionadas.length > 0
              ? this.filtroPagamento.empresasSelecionadas.join(',')
              : undefined,
          nomeCliente: this.filtroPagamento.nomeCliente || undefined,
          valorPagamento: this.filtroPagamento.valorPagamento
            ? this.filtroPagamento.valorPagamento.replaceAll('.', '').replaceAll(',', '.')
            : undefined,
          dataPagamentoInicial: this.filtroPagamentoDataInicial || undefined,
          dataPagamentoFinal: this.filtroPagamentoDataFinal || undefined,
          dataVendaInicial: this.filtroDataInicialVenda || undefined,
          dataVendaFinal: this.filtroDataFinalVenda || undefined,
          tipoPagamento:
            this.filtroPagamento.tipoPagamento.length > 0
              ? this.filtroPagamento.tipoPagamento.join(',')
              : undefined,
          conciliados: false
        }

        const response = await PagamentoService.buscarTudo({
          ...filtros,
          ...paginacao
        })

        const pagamentosComDataFormatada = response.content.map((response) => {
          const dataVenda = new Date(response.dataVenda)
          const dataVendaFormatada = `${dataVenda.getDate().toString().padStart(2, '0')}-${(
            dataVenda.getMonth() + 1
          )
            .toString()
            .padStart(2, '0')}-${dataVenda.getFullYear()}`

          return {
            ...response,
            dataVendaFormatada
          }
        })

        this.pagamentos = pagamentosComDataFormatada
        this.qtdeTotalPagamentos = response.totalElements

        this.buscarValoresTotaisPagamentos(filtros)
      } catch (erro) {
        this.snackbar.snackTextParam = `${erro.response.status} | ${
          erro.response.data.message === undefined
            ? erro.message
            : erro.response.data.message
            ? erro.response.data.message
            : 'Ocorreu um erro ao buscar os pagamentos'
        }`
        this.snackbar.snackTextParam = 'error'
        this.snackbar.showSnackParam = true
      } finally {
        this.buscandoPagamentos = false
      }
    },

    buscarValoresTotaisPagamentos(filtros) {
      PagamentoService.buscarValoresTotais(filtros)
        .then((response) => {
          this.sumValorPagamento = response.valorPagamento
          this.sumValorRetirada = response.valorRetirada
          this.sumValorTaxa = response.valorTaxa
          this.sumValorPagamentoSemTaxa = response.valorPagamentoSemTaxa
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os pagamentos.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoValores = false))
    },

    async limparSelecaoDeFiltrosPagamento() {
      this.filtroPagamento = {
        tipoPagamento: [],
        adquirente: this.itensAdquirente[0].value,
        empresasSelecionadas: []
      }

      await this.buscaAdquirentePadraoPelaOrganizacao('PAGAMENTO')

      this.filtroPagamentoDataInicial = ''
      this.filtroPagamentoDataFinal = ''
      this.filtroDataInicialVenda = ''
      this.filtroDataFinalVenda = ''
      this.filtroPagamentoDa
      this.optionsPagamentos = {
        page: 1,
        sortBy: [],
        itensPerPage: 0
      }
      this.changeFiltroPagamentoAdquirente(this.filtroPagamento.adquirente)
      this.buscarPagamentos()
    },

    relacionar() {
      if (this.filtroPagamento.adquirente != this.filtroTitulo.adquirente) {
        this.snackbar.snackTextParam =
          'Não é possivel conciliar quando há diferença entre adquirentes. Sincronize ambos e tente novamente.'
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
        return
      }
      if (this.pagamentoSelecionado.length === 0 || this.titulosSelecionados.length === 0) {
        this.snackbar.snackTextParam =
          'Necessário selecionar um pagamento e um ou mais títulos para conciliar.'
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
        return
      }

      const pagamento = this.pagamentoSelecionado[0]

      const indexPagamento = this.pagamentos.indexOf(pagamento)
      const indexTitulos = this.titulosSelecionados.map((titulo) => this.titulos.indexOf(titulo))

      const titulosIds = this.titulosSelecionados.map((titulo) => titulo.id)

      ConciliacaoService.vincularTituloComPagamento(pagamento.id, titulosIds)
        .then(() => {
          this.snackbar.snackTextParam = 'Títulos relacionados com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true

          this.pagamentos.splice(indexPagamento, 1)

          indexTitulos.forEach((titulo) => {
            this.titulos.splice(titulo, 1)
          })

          this.pagamentoSelecionados = []
          this.titulosSelecionados = []
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os pagamentos'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    },

    conciliarAutomaticamente() {
      ConciliacaoService.vincularPagamentoAutomatico()
        .then(() => {
          this.snackbar.snackTextParam = 'Conciliação automática iniciada.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true
          this.atualizarDados()
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao vincular automaticamente'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    },

    conciliarAutomaticamentePorFiltro() {
      if (this.filtroPagamento.adquirente != this.filtroTitulo.adquirente) {
        this.snackbar.snackTextParam =
          'Não é possivel conciliar quando há diferença entre adquirentes. Sincronize ambos e tente novamente.'
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
        return
      }

      const filtrosPagamento = {
        adquirente: this.filtroPagamento.adquirente || undefined,
        codigoPagamento: this.filtroPagamento.codigoPagamento || undefined,
        nomeCliente: this.filtroPagamento.nomeCliente || undefined,
        valorPagamento: this.filtroPagamento.valorPagamento || undefined,
        dataPagamentoInicial: this.filtroPagamentoDataInicial || undefined,
        dataPagamentoFinal: this.filtroPagamentoDataFinal || undefined,
        tipoPagamento:
          this.filtroPagamento.tipoPagamento.length > 0
            ? this.filtroPagamento.tipoPagamento.join(',')
            : undefined,
        conciliados: false,
        empresasIds:
          this.filtroPagamento.empresasSelecionadas.length > 0
            ? this.filtroPagamento.empresasSelecionadas.join(',')
            : undefined
      }

      const filtrosTitulo = {
        adquirenteTitulos: this.filtroTitulo.adquirente || undefined,
        codigoTitulo: this.filtroTitulo.codigoTitulo || undefined,
        valorTitulo: this.filtroTitulo.valorTitulo || undefined,
        codigoAutorizacaoAdquirente: this.filtroTitulo.codigoAutorizacaoAdquirente || undefined,
        empresasIdsTitulos:
          this.filtroTitulo.empresasSelecionadas.length > 0
            ? this.filtroTitulo.empresasSelecionadas.join(',')
            : undefined,
        dataInicialVencimentoTitulo: this.filtroDataVencimentoInicial || undefined,
        dataFinalVencimentoTitulo: this.filtroDataVencimentoFinal || undefined,
        dataInicialEmissaoTitulo: this.filtroDataEmissaoInicial || undefined,
        dataFinalEmissaoTitulo: this.filtroDataEmissaoFinal || undefined,
        conciliadoTitulos: false
      }

      ConciliacaoService.vincularPorFiltro(filtrosPagamento, filtrosTitulo)
        .then(() => {
          this.snackbar.snackTextParam = 'Conciliação automática iniciada.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true
          this.atualizarDados()
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao vincular automaticamente'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    },

    exportarPagamentoSemConciliacaoXls() {
      const filtroParams = {
        adquirente: this.filtroPagamento.adquirente,
        codigoPagamento: this.filtroPagamento.codigoPagamento || undefined,
        empresasIds:
          this.filtroPagamento.empresasSelecionadas.length > 0
            ? this.filtroPagamento.empresasSelecionadas.join(',')
            : undefined,
        nomeCliente: this.filtroPagamento.nomeCliente || undefined,
        valorPagamento: this.filtroPagamento.valorPagamento
          ? this.filtroPagamento.valorPagamento.replaceAll('.', '').replaceAll(',', '.')
          : undefined,
        dataPagamentoInicial: this.filtroPagamentoDataInicial || undefined,
        dataPagamentoFinal: this.filtroPagamentoDataFinal || undefined,
        tipoPagamento:
          this.filtroPagamento.tipoPagamento.length > 0
            ? this.filtroPagamento.tipoPagamento.join(',')
            : undefined,
        conciliados: false
      }
      this.buscandoXls = true

      PagamentoService.exportPagamentoSemConciliacao(filtroParams)
        .then((response) => {
          this.exportarPagamentos = response
          this.downloadzip()
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os pagamentos.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoValores = false))
    },

    downloadzip() {
      let zip = new JSZip()
      zip.file('pagamentos' + '.xls', this.exportarPagamentos)

      zip
        .generateAsync({
          type: 'base64'
        })
        .then(function (content) {
          var link = document.createElement('a')
          link.href = 'data:application/zip;base64,' + content
          link.download = 'pagamentos.zip'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .finally(() => (this.buscandoXls = false))
    },

    exportarTitulosXls() {
      this.buscandoXls = true
      const filtroParams = {
        adquirente: this.filtroTitulo.adquirente || undefined,
        codigoTitulo: this.filtroTitulo.codigoTitulo || undefined,
        valorTitulo: this.filtroTitulo.valorTitulo
          ? this.filtroTitulo.valorTitulo.replaceAll('.', '').replaceAll(',', '.')
          : undefined,
        codigoAutorizacaoAdquirente: this.filtroTitulo.codigoAutorizacaoAdquirente || undefined,
        empresasIds:
          this.filtroTitulo.empresasSelecionadas.length > 0
            ? this.filtroTitulo.empresasSelecionadas.join(',')
            : undefined,
        dataInicialVencimentoTitulo: this.filtroDataVencimentoInicial || undefined,
        dataFinalVencimentoTitulo: this.filtroDataVencimentoFinal || undefined,
        dataInicialEmissaoTitulo: this.filtroDataEmissaoInicial || undefined,
        dataFinalEmissaoTitulo: this.filtroDataEmissaoFinal || undefined,
        conciliados: false
      }

      TituloService.exportarTitulosSemConciliacao(filtroParams)
        .then((response) => {
          this.exportarTitulo = response
          this.downloadzipTitulo()
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os titulos.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoValores = false))
    },

    downloadzipTitulo() {
      let zip = new JSZip()
      zip.file('titulos' + '.xls', this.exportarTitulo)

      zip
        .generateAsync({
          type: 'base64'
        })
        .then(function (content) {
          var link = document.createElement('a')
          link.href = 'data:application/zip;base64,' + content
          link.download = 'titulos.zip'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .finally(() => (this.buscandoXls = false))
    }
  }
}
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: calc(0.4rem + 1vmin);
}

.v-sheet.v-card--hover {
  cursor: default;
}

::v-deep .v-data-table-header {
  vertical-align: top;
}
</style>
