import request from '@/common/request'

class ProcessoService {
  buscarPeloNome(nomeProcesso) {
    return request.get(`/processos/${nomeProcesso}`)
  }

  buscarPeloNomeEOrganizacao(nomeProcesso) {
    return request.get(`/processos/organizacoes/${nomeProcesso}`)
  }
}

export default new ProcessoService()
